import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-02";
import Footer from "@layout/footer/layout-01";
import PageHeader from "@containers/page-header/layout-02";
import ContactArea from "@containers/contact/layout-02";
import DataImg from "../../assets/images/services/data.jpeg"

const ItSolutionsPage = ({ pageContext, location, data }) => {
    const content = normalizedData(data?.page?.content || []);
    const globalContent = normalizedData(data?.allGeneral.nodes || []);

    return (
        <Layout location={location}>
            <Seo titleTemplate="TEQT" title="Best Data Analytics Consulting - Custom Data Analytics Service"
                description="Use data to unlock the possibilities of your business. The data analytics services that we provide produce observable outcomes. You will be able to launch and maintain your data science use cases more efficiently with the assistance of our MLOps strategy."
            />
            <Header
                data={{
                    ...globalContent["header"],
                    ...globalContent["menu"],
                    socials: data.site.siteMetadata.socials,
                }}
            />
            <main className="site-wrapper-reveal">
                <PageHeader
                    pageContext={pageContext}
                    location={location}
                    title="IT outsourcing company"
                />
                <div className="service-container">
                    <div className="left-sec">
                        <img src={DataImg} alt="Custom Software Development" className="image" />
                    </div>
                    <div className="right-sec">
                        <h1>Data Analytics Solutions</h1>
                        <p className="mt-2">
                            Our Data Analytics Solutions service is designed to help businesses unlock the full potential of their data, enabling informed decision-making, driving growth, and gaining a competitive edge in their industries.
                        </p>
                    </div>
                </div>

                <div className="service-sub-container">
                    <h3>How It Works</h3>
                    <p className="mt-2">
                        Our Data Analytics Solutions service begins with a comprehensive assessment of your business objectives, data assets, and analytics requirements. Based on our findings, we develop a customized analytics strategy and implementation plan tailored to your specific needs. Our team of experienced data analysts then works closely with you to implement the solution, analyze the data, and uncover valuable insights that drive business growth and innovation.
                    </p>
                    <h3>Why Choose Us?</h3>
                    <p className="mt-2">
                        With years of experience in data analytics and a proven track record of successful projects across various industries, our team has the expertise and resources to help you harness the power of data for strategic decision-making and business success. Whether you're a small startup or a large enterprise, we're committed to delivering innovative data analytics solutions that drive real results for your business.
                    </p>
                </div>



                <ContactArea data={content["contact-section"]} />
            </main>
            <Footer data={{ ...data.site.siteMetadata }} />
        </Layout>
    );
};

export const query = graphql`
    query DataAnalyticsPageQuery {
        allGeneral {
            nodes {
                section
                ...HeaderTwo
            }
        }
        site {
            ...Site
        }
        page(title: { eq: "it-solutions" }, pageType: { eq: "innerpage" }) {
            content {
                ...PageContent
            }
        }
        allItSolution {
            nodes {
                ...ItSolutionThree
            }
        }
    }
`;

ItSolutionsPage.propTypes = {
    pageContext: PropTypes.shape({}),
    location: PropTypes.shape({}),
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        site: PropTypes.shape({
            siteMetadata: PropTypes.shape({
                contact: PropTypes.shape({}),
            }),
        }),
        page: PropTypes.shape({
            content: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allItSolution: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

export default ItSolutionsPage;
